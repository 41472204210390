import React from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
const SingUp = () => {

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            userName: '',
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
            .required('Name is required'),
            lastName: Yup.string()
            .required('Last name is required'),
            userName: Yup.string()
            .required('Username is required'),
            email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
            password: Yup.string()
            .required('Password is required'),
        }),
        onSubmit: values => {
            // Handle form submission
            console.log(values);
        }
    });

    const emailInputClasses = formik.touched.email && formik.errors.email ? 'error' : '';
    const passwordInputClasses = formik.touched.password && formik.errors.password ? 'error' : '';
    const nameInputClasses = formik.touched.name && formik.errors.name ? 'error' : '';
    const lastNameInputClasses = formik.touched.lastName && formik.errors.lastName ? 'error' : '';
    const userNameInputClasses = formik.touched.userName && formik.errors.userName ? 'error' : '';



    return (
        <form className='signUp' onSubmit={formik.handleSubmit}>
                <div className="form-group">
            <input
                type="text"
                id="name"
                placeholder='Name'
                name="name"
                className={nameInputClasses}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
            />
        </div>
        <div className="form-group">
            <input
                type="text"
                id="lastName"
                placeholder='Last Name'
                name="lastName"
                className={lastNameInputClasses}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
            />
        </div>
        <div className="form-group">
            <input
                type="text"
                id="userName"
                placeholder='Username'
                name="userName"
                className={userNameInputClasses}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
            />
        </div>
        <div className="form-group">
            <input
                type="text"
                id="email"
                placeholder='Email'
                name="email"
                className={emailInputClasses}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
            />
        </div>
        <div className="form-group">
            <input
                type="password"
                id="password"
                placeholder='Password'
                name="password"
                className={passwordInputClasses}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
            />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>

        <hr/>
        <div>
            <h3>Already have an account?</h3>
            <button onClick={() => navigate('/login')} style={{width:'100%'}}>Login</button>
        </div>
    </form>
    );
}

export default SingUp;




// import React from 'react';
//  import { Formik } from 'formik';
//  import * as Yup from 'yup'

// const signUpSchema = Yup.object().shape(
//     {
//         name: Yup.string()
//         .required('Name is required'),
//         lastName: Yup.string()
//         .required('Last name is required'),
//         userName: Yup.string()
//         .required('Username is required'),
//         email: Yup.string()
//         .email('Invalid email format')
//         .required('Email is required'),
//         password: Yup.string()
//         .required('Password is required')
//     }
// )

// let initialCredentials = {
//     name: '',
//     lastName: '',
//     userName: '',
//     email: '',
//     password: '',
// }
// const SignUp = () => (
//   <div>
//     <h1>SignUp page</h1>
//     <Formik
//       initialValues={initialCredentials}
//       validationSchema={signUpSchema}
//     //   validate={values => {
//     //     const errors = {};
//     //     if (!values.email) {
//     //       errors.email = 'Required';
//     //     } else if (
//     //       !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
//     //     ) {
//     //       errors.email = 'Invalid email address';
//     //     }
//     //     return errors;
//     //   }}
//       onSubmit={(values, { setSubmitting }) => {
//         setTimeout(() => {
//           alert(JSON.stringify(values, null, 2));
//           setSubmitting(false);
//         }, 400);
//       }}
//     >
//       {({
//         values,
//         errors,
//         touched,
//         handleChange,
//         handleBlur,
//         handleSubmit,
//         isSubmitting,
//         /* and other goodies */
//       }) => (
//         <form onSubmit={handleSubmit} className='signUp'>
//         <input
//             type="string"
//             name="name"
//             placeholder='Name'
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.name}
//           />
//           {errors.name && touched.name && <div className="error-message">{errors.name}</div>}
//           <input
//             type="string"
//             name="lastName"
//             placeholder='Last name'
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.lastName}
//           />
//           {errors.lastName && touched.lastName && <div className="error-message">{errors.lastName}</div>}
//           <input
//             type="string"
//             name="userName"
//             placeholder='User name'
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.userName}
//           />
//           {errors.userName && touched.userName && <div className="error-message">{errors.userName}</div>}
//           <input
//             type="email"
//             name="email"
//             placeholder='Email'
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.email}
//           />
//           {errors.email && touched.email && errors.email}
//           <input
//             type="password"
//             name="password"
//             placeholder='Password'
//             onChange={handleChange}
//             onBlur={handleBlur}
//             value={values.password}
//           />
//           {errors.password && touched.password && errors.password}
//           <button type="submit" disabled={isSubmitting}>
//             Submit
//           </button>
//         </form>
//       )}
//     </Formik>
//   </div>
// );

// export default SignUp
export const obtainAll = async(url, setNext, setLast, setPages, setTotal, setCharacters, setCharactersImg) => {
    const peticion = await fetch(url)
    const {info, results} = await peticion.json()
    setNext(info.next)
    info.prev != null ? setLast(info.prev) : setLast(null)
    setPages(info.pages)
    setTotal(info.count)
    setCharacters(results)
    setCharactersImg(results)
}

export const obtainCharacter = async(id, setCharacter) => {
    const peticion = await fetch(`https://rickandmortyapi.com/api/character/${id}
    `)
    const result = await peticion.json()
    setCharacter(result)
    console.log(result);
}
import React from 'react'
import { useNavigate } from 'react-router-dom'


export default function HomePage() {
    
    const navigate = useNavigate();
    const navigateProps = (path) => {
    navigate(path);
}
  return (

    <div>
        <div style={{ marginTop: '120px' }}></div>
      
      <div className='center'>
      <div className='home-cont'>
      <h1>Home page</h1>
            <h2>Dashboard</h2>

            <button onClick={() => navigate('/Introduction')}>
                Go to introduction page
            </button>
            </div>
        </div>
    </div>
  )
}

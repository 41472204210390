import React from 'react';
import SignUp from '../../components/pure/forms/sigUpForm';

const RegisterPage = () => {
    return (
        <div>
        <h1>Sign Up</h1>
            <SignUp></SignUp>
        </div>
    );
}

export default RegisterPage;

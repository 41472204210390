import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function IntroductionPage() {

    const navigate = useNavigate();

  return (

    <div >
    <div style={{ marginTop: '120px' }}></div>
    <div className='center' >
      <h1>My personal page, here you can find more about me</h1>
      {/* TODO */}
      <br/>
      <text>Esta pagina es la que vamos a recrear: <a href='https://daniel-espanadero.com/'
      target='_blank' 
      rel='noopener noreferrer'
      >
        https://daniel-espanadero.com/
      </a></text>
      <div className='hola'>
        <h2>Hola, <br/>
          soy desarrollador web con gran pasion y ganas por aprender y seguir creciendo en este mundo.</h2>
      </div>
    
        <div className='tecnologias'>
          <h2>Technologies i work with:</h2>
          <div className='logos'>
            {/* <img src='https://cdn-icons-png.flaticon.com/512/174/174854.png' alt='html'></img> */}
            <img src='https://www.azulschool.net/wp-content/uploads/group-avatars/21/60ea4399467f2-bpfull.png' alt='css'></img>
            <img src='https://alu0100766950.github.io/img/nodejs.png' alt='js'></img>
            <img src='https://cdn.worldvectorlogo.com/logos/react-native-1.svg' alt='react'></img>
            <img src='' alt='sass'></img>
            <img src='' alt='mysql'></img>
            <img src='' alt='npm'></img>
            <img src='' alt='github'></img>
            <img src='' alt='git'></img>
            <img src='' alt='php'></img>
            <img src='' alt='docker'></img>
            <img src='' alt='nodejs'></img>
          </div>


      </div>
      </div>
    </div>
  )
}

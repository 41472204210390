import React from 'react';
import { LogIn } from '../../components/pure/forms/loginForm';



const Loginpage = () => {
    return (
        <div>
            <div className='center'>
                <LogIn></LogIn>
            </div>
        </div>
    );
}

export default Loginpage;

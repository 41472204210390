import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import Loginpage from "./pages/auth/LoginPage";
import './App.css';
import RegisterPage from "./pages/auth/RegisterPage";
import HomePage from "./pages/home/HomePage";
import ProfilePage from "./pages/profile/ProfilePage";
import NotFoundPage from "./pages/404/NotFoundPage";
import IntroductionPage from "./pages/introduction/introductionPage";
import AppR from "./pages/rickMorty/RickMorty";
import logo from './documents/logo-pagia-aarongarcia.png';


function App() {
  return (
    <Router>
        <div className='top-banner'>
        <div className='b'>
        <img src={logo} alt="logo" ></img>
        </div>
        <div className='b'>
        <Link className="Links" to={'/login'}>| Log in |</Link>
        <Link className="Links" to={'/'}>| Home |</Link>
        <Link className="Links" to={'/Introduction'}>| Introduction |</Link>
        <Link className="Links" to={'/rick&morty'}>| Rick y morty api |</Link>
        </div>
        {/* <div className='b'>c</div> */}
      </div>
    <div className="linkContainer">


    
    {/* <aside>
      <Link className="Links" to={'/login'}>|| Log in |</Link>
      <Link className="Links" to={'/signup'}>|| Sign up |</Link>
      <Link className="Links" to={'/'}>|| Home |</Link>
      <Link className="Links" to={'/profile'}>|| Profile |</Link>
      <Link className="Links" to={'/Introduction'}>|| Introduction |</Link>
      <Link className="Links" to={'/rickmorty'}>|| Rick y morty |</Link>
    </aside> */}
    </div>
      <Routes>
        <Route exact path="/" element={<HomePage/>}></Route>
        <Route path="/login" element={<Loginpage/>}></Route>
        <Route path="/signup" element={<RegisterPage/>}></Route>
        <Route path="/profile" element={<ProfilePage/>}></Route>
        <Route path="/introduction" element={<IntroductionPage/>}></Route>
        <Route path="/rick&morty" element={<AppR/>}></Route>
        <Route path="*" element={<NotFoundPage/>}></Route>
      </Routes>
  
    </Router>
  );
}
export default App;